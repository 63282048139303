<template>
  <v-card flat>
    <v-card-title
      >{{ $t("camOnline.servers") }} <v-spacer></v-spacer>
      <cam-online-server-create-dialog />
    </v-card-title>

    <v-card-text>
      <v-data-table :headers="headers" :items="servers">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="selectRemove(item.camOnlineServerId)" icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <delete-dialog
      :dialog="confirmDelete"
      v-on:confirm-delete="remove"
      v-on:cancel-dialog="confirmDelete = false"
    />
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CamOnlineServerCreateDialog from "./CamOnlineServerCreateDialog.vue";
import DeleteDialog from "../common/DeleteDialog.vue";

export default {
  components: { CamOnlineServerCreateDialog, DeleteDialog },
  name: "CamOnlineList",
  data() {
    return {
      id: null,
      confirmDelete: false,
      headers: [
        { text: this.$t("camOnline.fields.url"), value: "url" },
        { text: this.$t("camOnline.fields.isGlobal"), value: "isGlobal" },
        { text: "", value: "actions" },
      ],
    };
  },

  methods: {
    ...mapActions("camOnline", ["getServers", "deleteServer"]),

    selectRemove(id) {
      this.id = id;
      this.confirmDelete = true;
    },

    async remove() {
      await this.deleteServer(this.id);
      this.confirmDelete = false;
    },
  },

  computed: {
    ...mapState("camOnline", ["servers"]),
  },
};
</script>