<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary">
        <v-icon>mdi-plus</v-icon> {{ $t("accessToken.newToken") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>{{ $t("accessToken.cardNewTitle") }}</span>
      </v-card-title>
      <v-form
        :lazy-validation="true"
        @submit.prevent="handleSubmit"
        v-model="valid"
      >
        <v-card-text>
          <v-text-field
            v-model="label"
            :label="$t('accessToken.fields.label')"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-switch
            v-model="isCompany"
            :label="$t('accessToken.fields.isCompany')"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" type="submit" x-large>{{
            $t("common.save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AccessTokenCreate",
  data() {
    return {
      dialog: false,
      label: "",
      isCompany: true,
      valid: false,
    };
  },

  methods: {
    ...mapActions("accessTokens", ["createToken"]),
    async handleSubmit() {
      await this.createToken({ label: this.label, isCompany: this.isCompany });
      this.$emit("update-tokens");
      this.dialog = false;
    },
  },
};
</script>
