<template>
  <v-dialog v-model="localValue" width="700">
    <v-card>
      <v-card-title>{{ $t("opc.server.create") }}</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="serverUrl"
          :label="$t('opc.server.fields.serverUrl')"
          prepend-inner-icon="mdi-web"
        />
        <v-text-field
          v-model="username"
          :label="$t('opc.server.fields.username')"
          prepend-inner-icon="mdi-account"
        />

        <v-text-field
          v-model="password"
          :label="$t('opc.server.fields.password')"
          type="password"
          prepend-inner-icon="mdi-lock"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text small @click="reset">{{ $t("common.close") }}</v-btn>
        <v-btn text small @click="save">{{ $t("common.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    server: {
      type: Object,
      default: undefined,
    },
  },

  model: { prop: "value" },

  data() {
    return {
      serverUrl: "http://localhost",
      username: "admin",
      password: "admin",
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    reset() {
      this.localValue = false;
      this.serverUrl = "http://localhost";
      this.username = "admin";
      this.password = "admin";
    },

    save() {
      this.$emit("save", {
        serverUrl: this.serverUrl,
        username: this.username,
        password: this.password,
      });
      this.reset();
    },
  },

  watch: {
    server(v) {
      this.serverUrl = v?.serverUrl;
      this.username = v?.username;
      this.password = v?.password;
    },
  },
};
</script>
