<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("opc.server.title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="openForm"
        >{{ $t("opc.server.create") }}
      </v-btn>
    </v-toolbar>

    <p
      v-if="errorMessage !== undefined && errorMessage !== ''"
      class="error text-left pa-3"
    >
      {{ errorMessage }}
    </p>

    <v-data-table :items="servers" :headers="headers">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="selectEdit(item)" icon>
          <v-icon color="primary">mdi-pen</v-icon>
        </v-btn>

        <v-btn @click="selectRemove(item)" icon>
          <v-icon color="red">mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ humanDate(item.updatedAt) }}
      </template>

      <template v-slot:item.useUsernameAndPassword="{ item }">
        <v-chip :color="item.useUsernameAndPassword ? 'green' : 'red'">
          {{ item.useUsernameAndPassword ? $t("common.yes") : $t("common.no") }}
        </v-chip>
      </template>
    </v-data-table>

    <opc-server-form-dialog
      v-model="formDialog"
      :master="selectedItem"
      @save="save"
    />

    <delete-dialog
      :dialog="confirmDelete"
      v-on:confirm-delete="remove"
      v-on:cancel-dialog="confirmDelete = false"
    />
  </v-container>
</template>

<script>
import OpcRepository from "@/api/repositories/opcRepository";
import DeleteDialog from "../common/DeleteDialog.vue";
import OpcServerFormDialog from "./OpcServerFormDialog.vue";

export default {
  name: "OpcServerList",

  components: { DeleteDialog, OpcServerFormDialog },

  data() {
    return {
      servers: [],

      formDialog: false,
      confirmDelete: false,
      selectedItem: undefined,

      errorMessage: "",

      headers: [
        {
          text: this.$t("opc.server.fields.opcServerId"),
          value: "opcServerId",
        },
        { text: this.$t("opc.server.fields.serverUrl"), value: "serverUrl" },
        {
          text: this.$t("opc.server.fields.useUsernameAndPassword"),
          value: "useUsernameAndPassword",
        },
        { text: this.$t("common.updatedAt"), value: "updatedAt" },
        { text: this.$t("common.createdAt"), value: "createdAt" },
        {
          text: "",
          value: "actions",
        },
      ],
    };
  },

  methods: {
    async remove() {
      const errMsg = await OpcRepository.deleteServer(
        this.selectedItem.modbusMasterId
      )
        .then(() => undefined)
        .catch((d) => d.response.data);
      this.confirmDelete = false;
      this.errorMessage = errMsg ?? "";

      await this.fetch();
    },

    async save(values) {
      let errMsg;
      if (
        this.selectedItem === undefined ||
        this.selectedItem?.opcServerId === undefined
      ) {
        errMsg = await OpcRepository.createServer(values)
          .then(() => undefined)
          .catch((e) => e.response.data);
        this.errorMessage = errMsg ?? "";
      } else {
        errMsg = await OpcRepository.updateServer(
          this.selectedItem.opcServerId,
          values
        )
          .then(() => undefined)
          .catch((e) => e.response.data);
        this.errorMessage = errMsg ?? "";
      }

      await this.fetch();
    },

    selectRemove(item) {
      this.selectedItem = item;
      this.confirmDelete = true;
    },

    selectEdit(item) {
      this.selectedItem = item;
      this.formDialog = true;
    },

    openForm() {
      this.selectedItem = undefined;
      this.formDialog = true;
    },

    async fetch() {
      const [result, servers] = await OpcRepository.getServers()
        .then((d) => [true, d.data])
        .catch(() => [false, []]);

      if (!result) this.errorMessage = "Could not get OPC Servers from api";

      this.servers = servers;
    },
  },

  computed: {},

  async created() {
    await this.fetch();
  },
};
</script>

<style scoped>
/* Your styles go here */
</style>
