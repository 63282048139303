var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-start-align-center mt-2"},[_c('v-progress-linear',{staticClass:"mr-2",attrs:{"height":"25","color":_vm.precentileGateways < 80 ? 'success' : 'error',"value":_vm.precentileGateways}},[_c('span',{style:(_vm.precentileGateways > 80 ? 'color: white' : 'color: black;')},[_vm._v(_vm._s(_vm.$t("license.usedGateways"))+" ("+_vm._s(_vm.serverMeta.totalGateways)+" / "+_vm._s(_vm.maxGatewaysTitle)+")")])]),_c('v-progress-linear',{staticClass:"ml-2",attrs:{"height":"25","color":_vm.precentileNodes < 80 ? 'success' : 'error',"value":_vm.precentileNodes}},[_c('span',{style:(_vm.precentileNodes > 80 ? 'color: white' : 'color: black;')},[_vm._v(_vm._s(_vm.$t("license.usedNodes"))+" ("+_vm._s(_vm.serverMeta.totalNodes)+" / "+_vm._s(_vm.maxNodesTitle)+")")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.limitations},scopedSlots:_vm._u([{key:`item.updatedAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.updatedAt))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary","fab":"","x-small":""},on:{"click":() => {
            _vm.editDialog = true;
            _vm.selectedItem = item;
          }}},[_c('v-icon',[_vm._v("mdi-pen")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("license.editMaxTitle")))]),(_vm.selectedItem)?_c('v-card-text',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('license.limitations.fields.maxGateways'),"prepend-icon":"mdi-alpha-x"},model:{value:(_vm.selectedItem.maxGateways),callback:function ($$v) {_vm.$set(_vm.selectedItem, "maxGateways", $$v)},expression:"selectedItem.maxGateways"}}),_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('license.limitations.fields.maxNodes'),"prepend-icon":"mdi-alpha-x"},model:{value:(_vm.selectedItem.maxNodes),callback:function ($$v) {_vm.$set(_vm.selectedItem, "maxNodes", $$v)},expression:"selectedItem.maxNodes"}})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"gray"},on:{"click":() => {
              _vm.editDialog = false;
              _vm.selectedItem = undefined;
            }}},[_vm._v(_vm._s(_vm.$t("common.close")))]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.updateLimits}},[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }