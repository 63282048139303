<template>
  <div>
    <div class="d-flex justify-start-align-center mt-2">
      <v-progress-linear
        class="mr-2"
        height="25"
        :color="precentileGateways < 80 ? 'success' : 'error'"
        :value="precentileGateways"
      >
        <span
          :style="precentileGateways > 80 ? 'color: white' : 'color: black;'"
          >{{ $t("license.usedGateways") }} ({{ serverMeta.totalGateways }} /
          {{ maxGatewaysTitle }})</span
        >
      </v-progress-linear>
      <v-progress-linear
        class="ml-2"
        height="25"
        :color="precentileNodes < 80 ? 'success' : 'error'"
        :value="precentileNodes"
      >
        <span :style="precentileNodes > 80 ? 'color: white' : 'color: black;'"
          >{{ $t("license.usedNodes") }} ({{ serverMeta.totalNodes }} /
          {{ maxNodesTitle }})</span
        >
      </v-progress-linear>
    </div>
    <v-data-table :headers="headers" :items="limitations">
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ humanDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          @click="
            () => {
              editDialog = true;
              selectedItem = item;
            }
          "
          color="primary"
          fab
          x-small
          ><v-icon>mdi-pen</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <v-dialog max-width="700px" v-model="editDialog">
      <v-card>
        <v-card-title>{{ $t("license.editMaxTitle") }}</v-card-title>
        <v-card-text v-if="selectedItem">
          <v-text-field
            type="number"
            v-model="selectedItem.maxGateways"
            :label="$t('license.limitations.fields.maxGateways')"
            prepend-icon="mdi-alpha-x"
          />
          <v-text-field
            type="number"
            v-model="selectedItem.maxNodes"
            :label="$t('license.limitations.fields.maxNodes')"
            prepend-icon="mdi-alpha-x"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="
              () => {
                editDialog = false;
                selectedItem = undefined;
              }
            "
            text
            color="gray"
            >{{ $t("common.close") }}</v-btn
          >
          <v-btn color="primary" text @click="updateLimits">{{
            $t("common.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import licenseRepository from "../../api/repositories/licenseRepository";
export default {
  name: "LicenseCompanyLimitations",

  data() {
    return {
      editDialog: false,
      selectedItem: undefined,

      totalGateways: 0,
      totalNodes: 0,

      headers: [
        {
          text: this.$t("license.limitations.fields.companyId"),
          value: "companyId",
        },
        {
          text: this.$t("license.limitations.fields.companyName"),
          value: "company.name",
        },
        {
          text: this.$t("license.limitations.fields.maxGateways"),
          value: "maxGateways",
        },
        {
          text: this.$t("license.limitations.fields.maxNodes"),
          value: "maxNodes",
        },
        {
          text: this.$t("license.limitations.fields.currentNodes"),
          value: "currentNodes",
        },
        {
          text: this.$t("license.limitations.fields.currentGateways"),
          value: "currentGateways",
        },
        {
          text: this.$t("license.limitations.fields.updatedAt"),
          value: "updatedAt",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      limitations: [],
    };
  },

  computed: {
    ...mapState("users", ["currentUser"]),
    ...mapState("configuration", ["license", "serverMeta"]),

    maxGatewaysTitle() {
      let maxGws = this.license?.SO_MAX_GATEWAYS;
      if (maxGws == undefined || maxGws == 0) return "∞";
      return this.license?.SO_MAX_GATEWAYS;
    },

    maxNodesTitle() {
      let maxNodes = this.license?.SO_MAX_NODES;
      if (maxNodes == undefined || maxNodes == 0) return "∞";
      return this.license?.SO_MAX_NODES;
    },

    precentileGateways() {
      if (this.license?.SO_MAX_GATEWAYS && this.serverMeta?.totalGateways) {
        if (this.license.SO_MAX_GATEWAYS == 0) return 0;
        return Math.ceil(
          (this.serverMeta.totalGateways / this.license.SO_MAX_GATEWAYS) * 100
        );
      }

      return 0;
    },

    precentileNodes() {
      if (this.license?.SO_MAX_NODES && this.serverMeta?.totalNodes) {
        if (this.license.SO_MAX_NODES == 0) return 0;
        return Math.ceil(
          (this.serverMeta.totalNodes / this.license.SO_MAX_NODES) * 100
        );
      }

      return 0;
    },
  },

  methods: {
    ...mapActions("configuration", ["getServerMeta"]),

    async updateLimits() {
      this.editDialog = false;

      if (
        this.selectedItem?.companyId == undefined ||
        this.selectedItem?.maxGateways == undefined ||
        this.selectedItem?.maxNodes == undefined
      ) {
        console.error("SelectedItem: " + this.selectedItem);
        this.$emit("error", this.$t("license.errors.couldNotSetLimits"));
        this.selectedItem = undefined;
        return;
      }

      var res = await licenseRepository
        .createOrUpdateCompanyLimitation(
          this.currentUser,
          this.selectedItem.companyId,
          this.selectedItem.maxGateways,
          this.selectedItem.maxNodes
        )
        .then(() => true)
        .catch(() => false);
      if (!res)
        this.$emit("error", this.$t("license.errors.couldNotSetLimits"));

      this.selectedItem = undefined;

      await this.fetchLimitations();
    },

    async fetchLimitations() {
      this.limitations = await licenseRepository
        .getCompanyLimitations(this.currentUser)
        .then((d) => d.data)
        .catch((e) => {
          console.error(e?.repsonse?.data ?? "Could not fetch limitations");
          return [];
        });
    },
  },

  async created() {
    await this.fetchLimitations();
    await this.getServerMeta();
  },

  watch: {
    async editDialog(val) {
      if (!val) await this.fetchLimitations();
    },
  },
};
</script>