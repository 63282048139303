<template>
  <v-dialog v-model="localValue" width="700">
    <v-card>
      <v-card-title>{{ $t("modbus.master.create") }}</v-card-title>
      <v-card-title>
        <v-text-field
          v-model="name"
          :label="$t('modbus.master.fields.name')"
          prepend-inner-icon="mdi-alpha-a"
        />

        <v-text-field
          v-model="host"
          :label="$t('modbus.master.fields.host')"
          prepend-inner-icon="mdi-ip"
        />

        <v-text-field
          type="number"
          v-model="port"
          :label="$t('modbus.master.fields.port')"
          prepend-inner-icon="mdi-midi-port"
        />
      </v-card-title>
      <v-card-actions
        ><v-spacer />

        <v-btn text small @click="reset">{{ $t("common.close") }}</v-btn>
        <v-btn text small @click="save">{{ $t("common.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    master: {
      type: Object,
      default: undefined,
    },
  },

  model: { prop: "value" },

  data() {
    return {
      name: "Modbus Master",
      host: "127.0.0.1",
      port: 502,
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    reset() {
      this.localValue = false;
      this.name = "Modbus Master";
      this.host = "127.0.0.1";
      this.port = 502;
    },

    save() {
      this.$emit("save", { name: this.name, host: this.host, port: this.port });
      this.reset();
    },
  },

  watch: {
    master(v) {
      this.name = v?.name;
      this.host = v?.host;
      this.port = v?.port;
    },
  },
};
</script>