var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tabs',[_c('v-tab',[_vm._v(_vm._s(_vm.$t("license.companySettings")))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t("license.licenseManagement")))]),_c('v-tab-item',[(_vm.error)?_c('div',{staticStyle:{"width":"100%","text-align":"center","color":"red"}},[_vm._v(" "+_vm._s(this.error)+" ")]):_vm._e(),_c('license-company-limitations',{on:{"error":(e) => (_vm.error = e)}})],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[(_vm.error)?_c('div',{staticStyle:{"width":"100%","text-align":"center","color":"red"}},[_vm._v(" "+_vm._s(this.error)+" ")]):_vm._e(),_c('v-text-field',{attrs:{"prepend-icon":"mdi-key","label":_vm.$t('license.publicKey')},model:{value:(_vm.publicKey),callback:function ($$v) {_vm.publicKey=$$v},expression:"publicKey"}}),(_vm.licenseFile === null)?_c('v-container',{staticClass:"pa-6 file-gray-hover file-drop-box",on:{"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[_c('input',{ref:"file",staticClass:"file-hidden-file-input",attrs:{"type":"file","id":"fileInput","name":"file","accept":".lic,.csv,.txt"},on:{"change":_vm.onChange}}),_c('label',{attrs:{"for":"fileInput"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("license.upload"))+" ")]),_c('small',{staticClass:"file-small-hint"},[_vm._v(" "+_vm._s(_vm.$t("license.uploadHint"))+" ")])])]):_c('v-btn',{attrs:{"text":""},on:{"click":_vm.clearFile}},[_vm._v(" Click to undo file-upload ")]),_c('br'),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"primary","disabled":_vm.licenseFile === null || _vm.publicKey == '' || _vm.publicKey == null},on:{"click":_vm.uploadLicense}},[_vm._v(_vm._s(_vm.$t("common.add")))]),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"items":_vm.licenses,"headers":[
            { text: _vm.$t('license.fields.status'), value: 'active' },
            { text: _vm.$t('license.fields.licenseId'), value: 'licenseId' },
            {
              text: _vm.$t('license.fields.maxCompanies'),
              value: 'maxCompanies',
            },
            { text: _vm.$t('license.fields.maxGateways'), value: 'maxGateways' },
            { text: _vm.$t('license.fields.maxNodes'), value: 'maxNodes' },
            { text: _vm.$t('license.fields.expires'), value: 'expires' },
            { text: _vm.$t('gateway.fields.createdAt'), value: 'createdAt' },
            { text: '', value: 'actions' },
          ]},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [_c('v-btn',{attrs:{"fab":"","x-small":"","color":item.active ? 'success' : 'error'},on:{"click":() => {
                  _vm.selectedId = item.licenseId;
                  _vm.setActiveDialog = true;
                }}},[_c('v-icon',[_vm._v(_vm._s(item.active ? "mdi-power-on" : "mdi-power-off")+" ")])],1)]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.createdAt))+" ")]}},{key:`item.expires`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.expires, "yyyy MMM dd"))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"fab":"","x-small":"","color":"error"},on:{"click":() => {
                  _vm.selectedId = item.licenseId;
                  _vm.confirmDelete = true;
                }}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1),_c('delete-dialog',{attrs:{"dialog":_vm.confirmDelete},on:{"confirm-delete":function($event){return _vm.remove(_vm.selectedId)},"cancel-dialog":function($event){_vm.confirmDelete = false}}}),_c('so-dialog',{on:{"accept":function($event){return _vm.activate(_vm.selectedId)}},model:{value:(_vm.setActiveDialog),callback:function ($$v) {_vm.setActiveDialog=$$v},expression:"setActiveDialog"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("common.verify"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }