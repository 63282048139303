<template>
  <v-container class="px-0 pt-5">
    <AccessTokenCreate v-on:update-tokens="updateTokens" />

    <DeleteDialog
      :dialog="confirmDelete"
      v-on:confirm-delete="deleteToken"
      v-on:cancel-dialog="confirmDelete = false"
    />
    <v-data-table :headers="headers" :items="tokens" :items-per-page="25">
      <template v-slot:[`item.token`]="{ item }">
        <v-btn icon color="primary" @click="() => openAccessToken(item.token)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.isCompany`]="{ item }">
        <v-chip :color="item.isCompany ? 'success' : 'warning'" outlined>
          <v-icon dark v-if="item.isCompany">mdi-check</v-icon>
          <v-icon dark v-if="!item.isCompany">mdi-close</v-icon>
        </v-chip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          icon
          color="error"
          @click="() => deleteTokenConfirm(item.accessTokenId)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="showAccessToken" max-width="570">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t("accessToken.cardTitle") }}
          </span>
        </v-card-title>

        <v-card-text>
          <p class="token-container-style">{{ token }}</p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeAccessToken">{{
            $t("common.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DeleteDialog from "@/components/common/DeleteDialog";
import AccessTokenCreate from "./AccessTokenCreate";
export default {
  name: "AccessTokens",

  data() {
    return {
      headers: [
        { text: this.$t("accessToken.fields.id"), value: "accessTokenId" },
        { text: this.$t("accessToken.fields.label"), value: "label" },
        { text: this.$t("accessToken.fields.token"), value: "token" },
        { text: this.$t("accessToken.fields.isCompany"), value: "isCompany" },
        { text: "", value: "action" },
      ],
      tokens: [],
      dialog: false,
      showAccessToken: false,
      confirmDelete: false,
      tokenId: null,
      token: "",
    };
  },

  computed: {},

  methods: {
    ...mapActions("accessTokens", ["getAccessTokens", "deleteAccessToken"]),

    openAccessToken(token) {
      this.token = token;
      this.showAccessToken = true;
    },

    closeAccessToken() {
      this.token = null;
      this.showAccessToken = false;
    },

    deleteTokenConfirm(id) {
      this.tokenId = id;
      this.confirmDelete = true;
    },

    async deleteToken() {
      if (this.tokenId == null) return;

      await this.deleteAccessToken(this.tokenId);
      this.tokenId = null;
      this.tokens = await this.getAccessTokens();
      this.confirmDelete = false;
    },

    async updateTokens() {
      this.tokens = await this.getAccessTokens();
    },
  },

  async created() {
    this.tokens = await this.getAccessTokens();
  },

  components: {
    DeleteDialog,
    AccessTokenCreate,
  },
};
</script>

<style>
.token-container-style {
  background: rgba(0, 0, 0, 0.062);
  border-radius: 10px;
  border: 1px solid black;
  padding: 3rem;
}
</style>
