<template>
  <v-container v-if="loaded">
    <v-row v-for="(value, name) in globalSettings" :key="name">
      <v-text-field
        v-if="!limitedToWhiteLabel(name) && name != 'SMS_PROVIDER'"
        outlined
        :label="$t('settings.global.' + name)"
        v-model="settings[name]"
      >
      </v-text-field>

      <v-select
        v-if="name == 'SMS_PROVIDER'"
        outlined
        :label="$t('settings.global.SMS_PROVIDER')"
        v-model="settings[name]"
        :items="['IP1', 'TWILIO']"
      ></v-select>
    </v-row>
    <v-btn
      v-if="
        Object.keys(settings).length -
          (whiteLabelActive() ? 0 : whiteLabelSettings.length) >
        0
      "
      @click="handleSubmit"
      x-large
      color="primary"
      type="submit"
      >{{ $t("common.save") }}</v-btn
    >
    <v-card-title v-else class="justify-center">
      {{ $t("common.noDataFound") }}
    </v-card-title>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "GlobalSettings",

  data() {
    return {
      settings: null,
      loaded: false,
    };
  },

  async created() {
    await this.getGlobalSettings();

    this.settings = this.globalSettings;

    this.loaded = true;
  },

  methods: {
    ...mapActions("configuration", ["getGlobalSettings", "setGlobalSettings"]),

    async handleSubmit() {
      await this.setGlobalSettings(this.settings);

      this.$router.go();
    },
  },

  computed: {
    ...mapState("configuration", ["globalSettings"]),
    ...mapState("users", ["currentUser"]),
  },
};
</script>